import {TextField, Box, Typography} from "@mui/material";
import * as React from "react";
import {forwardRef, useImperativeHandle, useState} from "react";

const OneTextInput = forwardRef(({setText = (x) => console.log(x), active = true, senden, children, inputName}, ref) => {
    const [value, setValue] = useState('')

    const handleTextChange = (e) => {
        setValue(e.target.value)
        setText(e.target.value)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault(); // Verhindert das Hinzufügen einer neuen Zeile
            senden(); // Ruft die Senden-Funktion auf
        }
    }

    useImperativeHandle(ref, () => ({
        getValue: () => value,
        clear: () => {setValue('')}
    }))

    return (
        <Box>
            {children}
            <TextField
                placeholder={inputName}
                multiline
                minRows={4}
                maxRows={10}
                sx={{
                    width: "100%",
                    height:"100%"
                }}
                onChange={handleTextChange}
                onKeyDown={handleKeyDown} // Fügt das Event-Handling hinzu
                value={value}
                disabled={!active}
            />
            <Typography
                variant="caption"
                align="center"
            >
            </Typography>

        </Box>
    );
})

export default OneTextInput;
