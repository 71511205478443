import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import OneTextInput from "./OneTextInput";
import {Box, MenuItem, Select, TableCell, TableRow, TextField} from "@mui/material";
import {RequestContext} from "../../App";
import FileUpload from "../FileUpload";

const EinfacheSpracheMainComp = forwardRef(({setFormData, inputName, senden, setAdditionalInfo}, ref) => {
    const [info, setInfo] = useState({})
    const [text, setText] = useState("")

    const requestCon = useContext(RequestContext)
    const [file, setFile] = useState(null);
    const [uploadActive, setUploadActive] = useState(true);
    const [textActive, setTextActive] = useState(true);

    const inputRef = useRef()


    useImperativeHandle(ref, () => ({
        getAddInfo: buildAddInfo,
        clearInput: inputRef?.current?.clear,
        getMsgInfo: buildMsgInfo
    }))

    const buildMsgInfo=()=>{
        requestCon["messages"] = []
        return([])
    }

    useEffect(() => {
        console.log(inputRef?.current?.getValue())
        if(file != null){
            setTextActive(false)
            setUploadActive(true)
        }else if(inputRef?.current?.getValue() != ""){
            setTextActive(true)
            setUploadActive(false)
        }else{
            setTextActive(true)
            setUploadActive(true)
        }

    }, [file,text]);


    useEffect(() => {
        if(file != null){
            requestCon["task"] = "plain_speech_upload"
            console.log(requestCon)
        }else {
            requestCon["task"] = "plain_speech"
            console.log(requestCon)
        }

    }, [file]);



    const buildAddInfo =()=>{
        requestCon["request"] = {
            "Text": inputRef?.current?.getValue(),
        }
        return({
            "Text": inputRef?.current?.getValue()
        })
    }

    return (
        <div>
            <TableRow>
                <TableCell >
                    <FileUpload active = {uploadActive} text={"Dokument hochladen"} acceptType={".pdf"} setFile={setFile} setFormData={setFormData} idText={"docs"}/>
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>oder</TableCell>
                <TableCell sx={{width: '100%'}}>
                    <OneTextInput active = {textActive} ref={inputRef} inputName={inputName} senden={senden} setText = {setText}>
                    </OneTextInput>
                </TableCell>
            </TableRow>
        </div>
    );
})
export default EinfacheSpracheMainComp;