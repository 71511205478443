import React from 'react'
import {List, ListItem, ListItemText} from '@mui/material';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";


function SidebarMainSettingsList({model, handleModelChange}) {


    return (
        <List>
            <ListItem key="modelChoice-text">
                <ListItemText>Wähle ein Modell:</ListItemText>
            </ListItem>
            <ListItem key="modelChoice">
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Modell</InputLabel>
                    <Select
                        labelId="model-select-label"
                        id="model-select"
                        value={model}
                        label="Modell"
                        onChange={handleModelChange}
                    >

                        {['OpenSource', 'OpenAI'].map((text, index) => (
                            <MenuItem value = {text}>{text}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </ListItem>
        </List>
    )
}

export default SidebarMainSettingsList