import React, {useState} from 'react'
import {Divider, List, ListItem, ListItemText, Typography} from '@mui/material';
import {FormControl, MenuItem, Select} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import PropTypes from "prop-types";
import AddIcon from '@mui/icons-material/Add';

function SidebarDetailSettings({setConvId, conversations, example, handleExampleChange, newConv, setMessages}) {
    const [beispielSelect, setBeispielSelect] = useState(["Rede","Zusammenfassung", "Einfache Sprache", "Chat", "Social Media Post", "Doc Q&A", "Transkription"])
    const conversationIds = [...new Set(conversations.flat().map(item => item.conversation_id))];



    const setupMessages=(convId)=>{
        setConvId(convId)
        setMessages(getMessagesByConversationId(convId))
    }
    const getMessagesByConversationId = (id) => {
        return conversations.flat().filter(item => item.conversation_id === id).map(item => item.message);
    };

    const handleChange = (event) => {
        handleExampleChange(event.target.value);
    };
    return (
    <List>
        <ListItem key = "exampleChoice-text" >
            <ListItemText primary={"Beispiele"} />
        </ListItem>
        <ListItem key = "exampleChoice">
            <FormControl fullWidth>
                <Select
                    labelId="example-select-label"
                    id="example-select"
                    value={example}
                    onChange={handleChange}
                >
                    {beispielSelect.map((text) => (
                        <MenuItem value={text}> {text}</MenuItem>
                    ))}
        
                </Select>
            </FormControl>
        </ListItem>
        <Divider/>
        <ListItem>
            <Typography>Gespräche</Typography>
        </ListItem>
        <ListItemButton key = "newConv-ListButton" onClick={newConv}>
            <AddIcon/> Neues Gespräch
        </ListItemButton>
        {conversations.flatMap((conversation) =>
            conversation
                .filter(({ title }, index, array) =>
                    array.findIndex(item => item.title === title) === index // Nur erster Eintrag mit Titel bleibt
                )
                .map(({ conversation_id, title }) => (
                    <ListItemButton
                        key={conversation_id}
                        onClick={() => setupMessages(conversation_id)}
                    >
                        {title || `Conversation ID: ${conversation_id}`}
                    </ListItemButton>
                ))
        )}

    </List>
  )
}

export default SidebarDetailSettings

SidebarDetailSettings.propTypes = {
    talksList: PropTypes.array
};

