import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import OneTextInput from "./OneTextInput";
import {Box, MenuItem, Select, TableCell, TableRow, TextField} from "@mui/material";
import {RequestContext} from "../../App";
import FileUpload from "../FileUpload";

const EinfacheEingabeMainComp = forwardRef(({setFormData, inputName, senden, setAdditionalInfo}, ref) => {
    const [info, setInfo] = useState({})
    const [text, setText] = useState("")

    const requestCon = useContext(RequestContext)
    const [file, setFile] = useState(null);

    const inputRef = useRef()


    useImperativeHandle(ref, () => ({
        getAddInfo: buildAddInfo,
        clearInput: inputRef?.current?.clear,
        getMsgInfo: buildMsgInfo
    }))

    const buildMsgInfo=()=>{
        requestCon["messages"] = []
        return([])
    }




    const buildAddInfo =()=>{
        requestCon["request"] = {
            "Text": inputRef?.current?.getValue(),
        }
        return({
            "Text": inputRef?.current?.getValue()
        })
    }

    return (
        <div>
                    <OneTextInput ref={inputRef} inputName={inputName} senden={senden}>
                    </OneTextInput>
        </div>
);
})
export default EinfacheEingabeMainComp;