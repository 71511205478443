import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import OneTextInput from "./OneTextInput";
import {Box, MenuItem, Select, Table, TableBody, TableCell, TableRow, TextField} from "@mui/material";
import TooltipWrapper from "../TooltipWrapper";
import FileUpload from "../FileUpload";
import {RequestContext} from "../../App";

const ZusammenfassungMainComp = forwardRef(({setFormData, inputName, senden, setAdditionalInfo}, ref) => {
    const [lengthTyp, setLengthTyp] = useState("Sätze")
    const [length, setLength] = useState(5)
    const [info, setInfo] = useState({})
    const [text, setText] = useState("")
    const [uploadActive, setUploadActive] = useState(true);
    const [textActive, setTextActive] = useState(true);
    const requestCon = useContext(RequestContext)
    const [file, setFile] = useState(null);

    const inputRef = useRef()

    useEffect(() => {
        console.log(inputRef?.current?.getValue())
        if(file != null){
            setTextActive(false)
            setUploadActive(true)
        }else if(inputRef?.current?.getValue() != ""){
            setTextActive(true)
            setUploadActive(false)
        }else{
            setTextActive(true)
            setUploadActive(true)
        }

    }, [file,text]);

    useImperativeHandle(ref, () => ({
        getAddInfo: buildAddInfo,
        clearInput: inputRef?.current?.clear,
        getMsgInfo: buildMsgInfo
    }))
    const buildMsgInfo=()=>{
        requestCon["messages"] = []
        return([])
    }

    useEffect(() => {
        if(file != null){
            requestCon["task"] = "summary_upload"
            console.log(requestCon)
        }else {
            requestCon["task"] = "summary"
            console.log(requestCon)
        }

    }, [file]);
    const buildAddInfo = () => {
        requestCon["request"] = {
            "Text": inputRef?.current?.getValue(),
            "Limit": length + " " + lengthTyp
        }

        return ({
            "Text": inputRef?.current?.getValue(),
            "Limit": length + " " + lengthTyp
        })
    }

    const handleLengthtypChange = (e) => {
        setLengthTyp(e.target.value)

    }
    const handleLengthChange = (e) => {
        setLength(e.target.value)
    }



    return (
        <div>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}></TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}></TableCell>
                        <TableCell>
                        <Box display={"flex"} flexDirection={"row-reverse"}>
                            <TooltipWrapper placement={"top"}
                                            title={"Sprachmodelle haben oft Probleme mit Längeneinschätzungen. Es kann sein, dass die Länge nicht richtig generiert wird."}>
                                <Box display={"flex"} flexDirection={"row-reverse"}>
                                    <Select style={{minWidth: 120}}
                                            sx={{
                                                mb: 1
                                            }}
                                            labelId="lengthSelect-label"
                                            id="lenghtSelect"
                                            value={lengthTyp}
                                            onChange={(e) => handleLengthtypChange(e)}

                                    >
                                        {['Sätze', 'Wörter', 'Zeichen'].map((text, index) => (
                                            <MenuItem value={text}> {text}</MenuItem>
                                        ))}
                                    </Select>
                                    <TextField sx={{
                                        maxWidth: 200
                                    }}
                                               label="Länge"
                                               defaultValue={5}
                                               fullWidth type={"number"}
                                               onChange={(e) => handleLengthChange(e)}/>
                                </Box>
                            </TooltipWrapper>
                        </Box>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <FileUpload active = {uploadActive} text={"Dokument hochladen"} acceptType={".pdf"} setFile={setFile} setFormData={setFormData} idText={"docs"}/>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}> oder</TableCell>
                        <TableCell sx={{width: '100%'}}>
                            <OneTextInput active = {textActive} setText={setText} ref={inputRef} inputName={inputName} senden={senden}>
                            </OneTextInput>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
})

export default ZusammenfassungMainComp;