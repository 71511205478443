import React, { useState, useContext } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import { RequestContext } from "../App";

const FileUpload = ({active = true, setFile, acceptType, text, id }) => {
    const requestCon = useContext(RequestContext);
    const [uploadFile, setUploadFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            requestCon["docs"] = file;
            setUploadFile(file);
            setFile(file);
        }
    };

    const handleClearFile = () => {
        requestCon["docs"] = null;
        setUploadFile(null);
        setFile(null);
    };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        overflow: 'hidden',
        position: 'absolute',
    });

    return (
        <Box position="relative" display="inline-block">
            <Button
                component="label"
                role={undefined}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 2,
                    border: '2px dashed grey',
                    borderRadius: '10px',
                    width: '300px',
                    textAlign: 'center',
                }}
                disabled={!active}
            >
                <VisuallyHiddenInput
                    type="file"
                    accept={acceptType}
                    name="docs"
                    idText={id}
                    onChange={handleFileChange}
                />
                <Box>
                    <CloudUpload sx={{ fontSize: 60 }} />
                    <Typography variant="h6" component="div">
                        {uploadFile ? uploadFile.name : text}
                    </Typography>
                </Box>
            </Button>
            {uploadFile && (
                <IconButton
                    size="small"
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8
                    }}
                    onClick={handleClearFile}
                >
                    <ClearIcon />
                </IconButton>
            )}
        </Box>
    );
};

export default FileUpload;
