import {TextField, Box, Typography} from "@mui/material";
import * as React from "react";
import { useTheme } from '@mui/material/styles';
import OneTextInput from "./OneTextInput";
import {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {RequestContext} from "../../App";

const AnpassungMainComp = forwardRef(({inputName, senden}, ref) => {
    const theme = useTheme()
    const requestCon = useContext(RequestContext)
    const [info, setInfo] = useState({})
    const [text, setText] = useState("")

    const inputRef = useRef()

    useImperativeHandle(ref, () => ({
        getAddInfo: buildAddInfo,
        clearInput: inputRef?.current?.clear,
        getMsgInfo : buildMsgInfo
    }))

    const buildAddInfo=()=>{
        console.log("no new AddInfo")
    }



    const buildMsgInfo =()=>{
        console.log("HIER!!")
        requestCon["messages"] = [
            {
                "role": "user",
                "content": inputRef?.current?.getValue()
            }
        ]
       return( [
           {
               "role": "user",
               "content": inputRef?.current?.getValue()
           }
       ])
    }


    return (

            <OneTextInput ref={inputRef} inputName={inputName} senden = {senden} setMessage={setText}>
            </OneTextInput>

    );
})

export default AnpassungMainComp;
