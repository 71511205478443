import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from 'react';
import FileUpload from "../FileUpload";
import { Table, TableBody, TableCell, TableRow} from "@mui/material";
import OneTextInput from "./OneTextInput";
import {RequestContext} from "../../App";



const TranskriptionMainComp = forwardRef(({senden,setFormData, inputName, setAdditionalInfo}, ref) => {
    const requestCon = useContext(RequestContext)
    const [file, setFile] = useState(null);


    const inputRef = useRef()

    useImperativeHandle(ref, () => ({
        getAddInfo: buildAddInfo,
        clearInput: clearThis,
        getMsgInfo: buildMsgInfo
    }))

    const buildAddInfo =()=>{
        requestCon["task"] = "transcription"
        requestCon["request"] = {}

        return({})
    }

    const clearThis = () =>{
        //requestCon["docs"] = null
        //requestCon["files"] = null
    }

    const buildMsgInfo=()=>{
        requestCon["messages"] = []
        return([])
    }
    return (
        <div>
            <FileUpload text={"Audio oder Video  hochladen"} acceptType={".mp3,audio/*"} setFile={setFile} setFormData={setFormData} idText={"audio"} />
        </div>
    );
})

export default TranskriptionMainComp;